<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id != null">Edit {{ title }}</b>
      <b v-else>Create {{ title }}</b>
    </span>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Year</label>
        <year :year="data.year" @year="setYear"></year>
        <span class="text-danger text-sm" v-show="errors.has('Year')">{{
          errors.first("Year")
        }}</span>
      </div>
    </div>
    <!-- <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Personal</label>
        <personal :id="data.personalId" @personal="setPersonal"></personal>
        <span
          class="text-danger text-sm"
          v-show="errors.has('Personal')"
        >{{ errors.first('Personal') }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input class="w-full" label="Role" v-model="data.role" disabled />
      </div>
    </div>-->
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Version</label>
        <multiselect
          v-model="version.selected"
          :options="version.options"
          placeholder="Type to search"
          :max-height="125"
          :searchable="false"
          :allow-empty="false"
        ></multiselect>
        <span class="text-danger text-sm" v-show="errors.has('Version')">{{
          errors.first("Version")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Indicator</label>
        <multiselect
          v-model="indicator.selected"
          :options="indicator.options"
          placeholder="Type to search"
          :max-height="125"
          :searchable="false"
          :allow-empty="false"
        ></multiselect>
        <span class="text-danger text-sm" v-show="errors.has('Indicator')">{{
          errors.first("Indicator")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6" v-if="indicator.selected == 'Distribution Channel'">
      <div class="vx-col w-full">
        <label class="vs-input--label">{{ indicator.selected }}</label>
        <customer-category
          :id="customerCategory.id"
          @selected="setCustomerCategory"
        ></customer-category>
        <span
          class="text-danger text-sm"
          v-show="errors.has('CustomerCategory')"
          >{{ errors.first("CustomerCategory") }}</span
        >
      </div>
    </div>
    <div class="vx-row mb-6" v-if="indicator.selected == 'Product'">
      <div class="vx-col w-full">
        <label class="vs-input--label">{{ indicator.selected }}</label>
        <product :id="product.id" @selected="setProduct"></product>
        <span class="text-danger text-sm" v-show="errors.has('Product')">{{
          errors.first("Product")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6" v-if="indicator.selected == 'Product Category'">
      <div class="vx-col w-full">
        <label class="vs-input--label">{{ indicator.selected }}</label>
        <product-category
          :id="productCategory.id"
          @selected="setProductCategory"
        ></product-category>
        <span
          class="text-danger text-sm"
          v-show="errors.has('ProductCategory')"
          >{{ errors.first("ProductCategory") }}</span
        >
      </div>
    </div>
    <div class="vx-row mb-6" v-if="indicator.selected == 'Product Brand'">
      <div class="vx-col w-full">
        <label class="vs-input--label">{{ indicator.selected }}</label>
        <product-brand
          :id="productBrand.id"
          @selected="setProductBrand"
        ></product-brand>
        <span class="text-danger text-sm" v-show="errors.has('ProductBrand')">{{
          errors.first("ProductBrand")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6" v-if="indicator.selected == 'Sales Channel'">
      <div class="vx-col w-full">
        <label class="vs-input--label">{{ indicator.selected }}</label>
        <sales-channel
          :id="salesChannel.id"
          @selected="setSalesChannel"
        ></sales-channel>
        <span class="text-danger text-sm" v-show="errors.has('SalesChannel')">{{
          errors.first("SalesChannel")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6" v-if="indicator.selected == 'Territory Area'">
      <div class="vx-col w-full">
        <label class="vs-input--label">{{ indicator.selected }}</label>
        <territory-area
          :id="territoryArea.id"
          @selected="setTerritoryArea"
        ></territory-area>
        <span
          class="text-danger text-sm"
          v-show="errors.has('TerritoryArea')"
          >{{ errors.first("TerritoryArea") }}</span
        >
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Lock</label>
        <vs-switch
          color="dark"
          icon-pack="feather"
          vs-icon-on="icon-check-circle"
          vs-icon-off="icon-slash"
          v-model="data.status"
        >
          <span slot="on">Locked</span>
          <span slot="off">Opened</span>
        </vs-switch>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          v-if="data.status != 1"
          class="w-full"
          label="Target"
          v-model="data.totalTarget"
          name="Target"
        />
        <vs-input
          v-else
          class="w-full"
          label="Target"
          v-model="data.totalTarget"
          name="Target"
          disabled
        />
        <span class="text-danger text-sm" v-show="errors.has('Target')">{{
          errors.first("Target")
        }}</span>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import Personal from "./target-form-component/Personal";
import Year from "./target-form-component/Year";
import CustomerCategory from "./target-form-component/CustomerCategory";
import Product from "./target-form-component/Product";
import ProductCategory from "./target-form-component/ProductCategory";
import ProductBrand from "./target-form-component/ProductBrand";
import SalesChannel from "./target-form-component/SalesChannel";
import TerritoryArea from "./target-form-component/TerritoryArea";
export default {
  components: {
    Personal,
    Year,
    CustomerCategory,
    Product,
    ProductCategory,
    ProductBrand,
    SalesChannel,
    TerritoryArea,
  },
  props: {
    id: {
      type: Number,
    },
    baseUrl: {
      type: String,
    },
    title: {
      type: String,
    },
    action: {
      type: String,
    },
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          personalId: null,
          description: "",
          totalTarget: 0,
          totalCascade: 0,
          uplift: 0,
          year: null,
          role: "Company",
          level: 0,
          status: 0,
          yearly: {
            salesQuotaId: null,
          },
        },
        year: {
          companyId: null,
          year: null,
        },
        personal: {
          id: null,
          name: "",
          role: "",
        },
        indicator: {
          options: [
            "General",
            "Distribution Channel",
            "Product",
            "Product Category",
            "Product Brand",
            "Sales Channel",
            // "Territory Area"
          ],
          selected: "General",
        },
        version: {
          options: [
            "Distribute Evently",
            // "Base on Historical",
            // "Base on Forecast"
          ],
          selected: "Distribute Evently",
        },
        customerCategory: {
          id: null,
          name: "",
        },
        productCategory: {
          id: null,
          name: "",
        },
        productBrand: {
          id: null,
          name: "",
        },
        product: {
          id: null,
          name: "",
        },
        salesChannel: {
          id: null,
          name: "",
        },
        territoryArea: {
          id: null,
          code: "",
          name: "",
        },
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (!this.data.year) {
          this.errors.add({
            field: "Year",
            msg: "The Year field is required",
          });
          result = false;
        }

        let amount = parseInt(
          this.data.totalTarget.toString().replace(/\./g, "")
        );
        if (amount < 1) {
          this.errors.add({
            field: "Target",
            msg: "The Target field is must greater than 0",
          });
          result = false;
        }

        if (result) {
          this.$vs.loading();
          if (this.action == "edit") {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      let totalTarget = parseInt(
        this.data.totalTarget.toString().replace(/\./g, "")
      );
      let totalCascade = parseInt(
        this.data.totalCascade.toString().replace(/\./g, "")
      );
      if (this.data.uplift == 0) {
        totalCascade = totalTarget;
      }
      var status = 0;
      if (this.data.status) {
        status = 1;
      }
      return {
        territory_area_id: this.territoryArea.id,
        sales_channel_id: this.salesChannel.id,
        product_category_id: this.productCategory.id,
        product_brand_id: this.productBrand.id,
        product_id: this.product.id,
        customer_category_id: this.customerCategory.id,
        indicator: this.indicator.selected,
        version: this.version.selected,
        description: this.data.description,
        uplift: this.data.uplift,
        year: this.data.year,
        role: this.data.role,
        status: status,
        total_target: totalTarget,
        total_cascade: totalCascade,
      };
    },
    postData() {
      this.$http
        .post(this.baseUrl, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New Data Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.baseUrl + "/" + this.id, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Data Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      if (this.id) {
        this.$http
          .get(this.baseUrl + "/" + this.id)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.data.year = resp.data.year;
              this.data.totalTarget = resp.data.total_target;
              this.data.totalCascade = resp.data.total_cascade;
              this.data.uplift = resp.data.uplift;
              this.data.status = resp.data.status;
              this.indicator.selected = resp.data.indicator;
              this.version.selected = resp.data.version;
              this.territoryArea.id = resp.data.territory_area_id;
              this.salesChannel.id = resp.data.sales_channel_id;
              this.productCategory.id = resp.data.product_category_id;
              this.productBrand.id = resp.data.product_brand_id;
              this.product.id = resp.data.product_id;
              this.customerCategory.id = resp.data.customer_category_id;
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      }
    },
    setPersonal(val) {
      this.personal = val;
      this.data.personalId = val.id;
      this.data.role = val.role;
      this.data.level = val.level;
    },
    setYear(val) {
      this.year = val;
      this.data.year = val.year;
    },
    setCustomerCategory(val) {
      this.customerCategory = val;
    },
    setProduct(val) {
      this.product = val;
    },
    setProductCategory(val) {
      this.productCategory = val;
    },
    setProductBrand(val) {
      this.productBrand = val;
    },
    setSalesChannel(val) {
      this.salesChannel = val;
    },
    setTerritoryArea(val) {
      this.territoryArea = val;
    },
  },
  mounted() {
    if (this.id) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    "data.totalTarget": function (val) {
      val = val.toString().replace(/\./g, "");
      if (isNaN(parseInt(val))) {
        this.errors.add({
          field: "Target",
          msg: "The Target field is number required",
        });
      } else {
        this.errors.clear();
      }
      this.data.totalTarget = val
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      if (this.data.uplift == 0) {
        this.data.totalCascade = this.data.totalTarget;
      }
    },
    id(val) {
      if (val) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
    "data.year": function (val) {
      if (val) {
        this.errors.clear();
      } else {
        this.errors.add({
          field: "Year",
          msg: "The Year field is required",
        });
      }
    },
    "data.personalId": function (val) {
      if (val) {
        this.errors.clear();
      } else {
        this.errors.add({
          field: "Personal",
          msg: "The Personal field is required",
        });
      }
    },
    "indicator.selected": function (val) {
      var defaultObj = {
        id: null,
        name: "",
      };
      switch (val) {
        case "General":
          this.setProductBrand(defaultObj);
          this.setProductCategory(defaultObj);
          this.setProduct(defaultObj);
          this.setCustomerCategory(defaultObj);
          this.setSalesChannel(defaultObj);
          this.setTerritoryArea(defaultObj);
          break;
        case "Distribution Channel":
          this.setProductBrand(defaultObj);
          this.setProductCategory(defaultObj);
          this.setProduct(defaultObj);
          this.setSalesChannel(defaultObj);
          this.setTerritoryArea(defaultObj);
          break;
        case "Product Category":
          this.setProductBrand(defaultObj);
          this.setProduct(defaultObj);
          this.setCustomerCategory(defaultObj);
          this.setSalesChannel(defaultObj);
          this.setTerritoryArea(defaultObj);
          break;
        case "Product Brand":
          this.setProductCategory(defaultObj);
          this.setProduct(defaultObj);
          this.setCustomerCategory(defaultObj);
          this.setSalesChannel(defaultObj);
          this.setTerritoryArea(defaultObj);
          break;
        case "Product":
          this.setProductBrand(defaultObj);
          this.setProductCategory(defaultObj);
          this.setCustomerCategory(defaultObj);
          this.setSalesChannel(defaultObj);
          this.setTerritoryArea(defaultObj);
          break;
        case "Sales Channel":
          this.setProductBrand(defaultObj);
          this.setProductCategory(defaultObj);
          this.setProduct(defaultObj);
          this.setCustomerCategory(defaultObj);
          this.setTerritoryArea(defaultObj);
          break;
        case "Territory Area":
          this.setProductBrand(defaultObj);
          this.setProductCategory(defaultObj);
          this.setProduct(defaultObj);
          this.setCustomerCategory(defaultObj);
          this.setSalesChannel(defaultObj);
          break;
      }
    },
  },
};
</script>
