<template>
  <multiselect
    v-model="selected"
    :options="options"
    placeholder="Type to search"
    track-by="name"
    label="name"
    :max-height="125"
    @search-change="handleSearch"
    :showNoOptions="false"
    :allow-empty="false"
  >
    <span slot="noResult">Oops! No data found</span>
    <template slot="singleLabel" slot-scope="props">
      <span class="option__desc">
        <span class="option__title"
          >{{ props.option.code }} {{ props.option.name }}</span
        >
      </span>
    </template>

    <template slot="option" slot-scope="props">
      <div class="option__desc">
        <span class="option__title"
          >{{ props.option.code }} {{ props.option.name }}</span
        >
      </div>
    </template>
  </multiselect>
</template>
<script>
export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        options: [],
        selected: null,
        baseUrl: "/api/v1/master/product",
        search: "",
      };
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: {
            search: this.search,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.options = resp.data.records;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleSearch(query) {
      if (query != "") {
        this.search = query;
        this.getData();
      }
    },
    getDataById() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/" + this.id)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.selected = resp.data;
            // this.options = [];
            // this.options.push(this.selected);
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },
  mounted() {
    this.getData();
    if (this.id) {
      this.getDataById();
    }
  },
  watch: {
    selected(val) {
      if (val) {
        // this.options = [];
        // this.options.push(val);
        this.$emit("selected", {
          id: val.ID,
          name: val.name,
        });
      } else {
        // this.options = [];
        this.$emit("selected", {
          id: null,
          name: "",
        });
      }
    },
    id(val) {
      if (val) {
        this.getDataById();
      } else {
        this.selected = null;
      }
    },
  },
};
</script>