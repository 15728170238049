<template>
  <vx-card :title="title">
    <vs-tabs :value="active">
      <vs-tab label="Weekly Date">
        <date></date>
      </vs-tab>
      <vs-tab label="Sales Team">
        <div class="tab-text">
          <sales-team></sales-team>
        </div>
      </vs-tab>
      <vs-tab label="Target Distribute">
        <div class="tab-text">
          <target></target>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Date from "./Date";
import Target from "./Target";
import SalesTeam from "./SalesTeam";
export default {
  props: {
    active: {
      type: Number
    }
  },
  components: {
    Date,
    Target,
    SalesTeam
  },
  data() {
    return {
      title: "Target Management"
    };
  }
};
</script>