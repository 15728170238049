<template>
  <div>
    <div class="vx-row mb-6" style="width: 70%">
      <div class="vx-col sm:w-1/5 w-full flex items-center">
        <span>Work Team</span>
      </div>
      <div class="vx-col sm:w-3/5 w-full">
        <multiselect
          v-model="team.selected"
          :options="team.options"
          placeholder="Type to search"
          track-by="name"
          label="name"
          :max-height="125"
          :showNoOptions="false"
          :allow-empty="false"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
      <!-- <div class="vx-col sm:w-1/5">
        <vs-button
          class="mt-2"
          color="primary"
          type="border"
          icon-pack="feather"
          icon="icon-plus"
          @click="handleCreate()"
        >Create</vs-button>
      </div> -->
    </div>
    <div style="min-height: 400px">
      <div v-if="treeData.length == 0" class="vx-row">
        <span class="mr-auto ml-auto">
          <b>No Data to Show</b>
        </span>
      </div>
      <div v-else>
        <v-tree
          ref="tree"
          :canDeleteRoot="true"
          :data="treeData"
          :draggable="true"
          :tpl="tpl"
          :halfcheck="true"
          :multiple="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { VTree, VSelectTree } from "vue-tree-halower";

export default {
  data() {
    return {
      searchword: "",
      treeData: [],
      team: {
        selected: null,
        options: [],
      },
    };
  },
  methods: {
    search() {
      this.$refs.tree.searchNodes(this.searchword);
    },
    tpl(...args) {
      let { 0: node } = args;
      return (
        <span>
          <span domPropsInnerHTML={node.title}></span>
        </span>
      );
    },
    async asyncLoad(node) {
      const { checked = false } = node;
      this.$set(node, "loading", true);
      let pro = new Promise((resolve) => {
        setTimeout(resolve, 2000, ["async node1", "async node2"]);
      });
      this.$refs.tree.addNodes(node, await pro);
      this.$set(node, "loading", false);
      if (checked) {
        this.$refs.tree.childCheckedHandle(node, checked);
      }
    },
    getDataWorkTeam() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/work-team", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.team.options = resp.data.records;
            if (resp.data.records[0]) {
              this.team.selected = resp.data.records[0];
            }
            this.$vs.loading.close();
          } else {
            console.log(resp.data);
            this.$vs.loading.close();
          }
        });
    },
    getTreeData() {
      this.$vs.loading();
      this.$http
        .get(
          "/api/v1/master/work-team-personal/target-management/" +
            this.team.selected.id
        )
        .then((resp) => {
          if (resp.status == "success") {
            this.treeData = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    handleCreate() {
      this.$router.push("/master/work-team");
    },
  },
  components: {
    VTree,
    VSelectTree,
  },
  mounted() {
    this.getDataWorkTeam();
  },
  watch: {
    "team.selected": function () {
      this.treeData = [];
      this.getTreeData();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/extraComponents/tree.scss";

button.btn-async {
  background: rgba(var(--vs-warning), 0.15);
}

button.btn-delete {
  background: rgba(var(--vs-danger), 0.15);
}
</style>