<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="year != null">Edit {{ title }} {{ year }}</b>
      <b v-else>Create {{ title }}</b>
    </span>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input v-if="action == 'edit'" disabled class="w-full" label="Year" v-validate="'required'" name="Year"
          v-model="data.year" />
        <vs-input v-else class="w-full" label="Year" v-validate="'required'" name="Year" v-model="data.year" />
        <span class="text-danger text-sm" v-show="errors.has('Year')">{{ errors.first('Year') }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input class="w-full" label="Notes" name="Notes" v-model="data.notes" />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="vx-row mb-3 mt-3">
          <div class="vx-col w-1/3 mt-auto mb-auto" style="width:20%  !important">Week</div>
          <div class="vx-col w-1/3">Start Date</div>
          <div class="vx-col w-1/3">End Date</div>
        </div>
        <div v-for="(item, index) in data.weeks" :key="index">
          <div class="vx-row mb-3 mt-3">
            <div class="vx-col w-1/3 mt-auto mb-auto" style="width:20%  !important">{{ item }}</div>
            <div class="vx-col w-1/3">
              <flat-pickr :config="flatPickrConfig" v-model="data.weekList.start[item]" style="width:75%"
                placeholder="Start Date" />
            </div>
            <div class="vx-col w-1/3">
              <flat-pickr :config="flatPickrConfig" v-model="data.weekList.end[item]" style="width:75%"
                placeholder="End Date" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleClose">Close</vs-button>
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment"
export default {
  components: {
    flatPickr
  },
  props: {
    year: {
      type: Number
    },
    baseUrl: {
      type: String
    },
    title: {
      type: String
    },
    action: {
      type: String
    }
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          weeks: [],
          year: "",
          notes: "",
          weekList: {
            start: [],
            end: []
          }
        },
        flatPickrConfig: {
          maxDate: null,
          minDate: null
        }
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading();
          if (this.action == "edit") {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      var weekList = [];
      var end = this.data.weekList.end;
      this.data.weekList.start.forEach(
        function (val, index) {
          weekList.push({
            week: index,
            start_date: val,
            end_date: end[index]
          });
        },
        weekList,
        end
      );

      return {
        year: parseInt(this.data.year),
        week_list: weekList,
        notes: this.data.notes
      };
    },
    postData() {
      this.$http
        .post(this.baseUrl, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New Data Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.baseUrl + "/" + this.data.year, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Data Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      if (this.data.year) {
        this.$http
          .get(this.baseUrl + "/" + this.data.year)
          .then(resp => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.data.notes = resp.data[0].notes;
              var start = [];
              var end = [];
              resp.data.forEach(
                function (val) {
                  start[val.week] = val.start_date;
                  end[val.week] = val.end_date;
                },
                start,
                end
              );
              this.data.weekList.start = start;
              this.data.weekList.end = end;
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            }
          })
          .catch(error => {
            this.$vs.loading.close();
            console.log(error);
          });
      }
    },
    defaultData() {
      var i;
      for (i = 1; i <= 52; i++) {
        this.data.weeks.push(i);
        this.data.weekList.start[i] = null;
        this.data.weekList.end[i] = null;
      }
    },
    getDateOfWeek(w, y) {
      var d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week

      return new Date(y, 0, d);
    }
  },
  mounted() {
    this.defaultData();
    if (this.action == "edit" && this.year) {
      this.data.year = this.year;
      this.getData();
    }
  },
  computed: {},
  watch: {
    year(val) {
      if (val) {
        this.data.year = this.year;
        this.getData();
      } else {
        this.data.year = null;
        this.defaultData();
      }
    },
    "data.year": function (val) {
      if (this.action == "create" && val.toString().length >= 4) {
        for (let i = 1; i <= 52; i++) {
          let weekString = "W" + i
          if (i < 10) {
            weekString = "W0" + i
          }
          let start = moment(val + weekString).toDate()
          let end = new Date(start);
          end.setDate(start.getDate() + 6);
          this.data.weekList.start[i] = start;
          this.data.weekList.end[i] = end;
        }
      }
    },
    // "data.year": function(val) {
    //   if (this.action == "create" && val.toString().length >= 4) {
    //     var minDate = new Date(val, 0, 1);
    //     var maxDate = new Date(val, 11, 31);
    //     this.flatPickrConfig.minDate = minDate;
    //     this.flatPickrConfig.maxDate = maxDate;
    //     var i;
    //     var startNow = minDate;
    //     var endNow = maxDate;
    //     for (i = 1; i <= 52; i++) {
    //       var added = null;
    //       if (i == 1) {
    //         if (minDate.getDay() >= 1 && minDate.getDay() <= 4) {
    //           added = new Date(startNow);
    //           added.setDate(added.getDate() + (7 - minDate.getDay()));
    //           endNow = added;
    //         } else {
    //           if (minDate.getDay() == 0) {
    //             added = new Date(startNow);
    //             added.setDate(startNow.getDate() + 7);
    //             endNow = added;
    //           } else {
    //             added = new Date(startNow);
    //             added.setDate(
    //               startNow.getDate() + (7 + (7 - minDate.getDay()))
    //             );
    //             endNow = added;
    //           }
    //         }
    //       } else {
    //         added = new Date(endNow);
    //         added.setDate(added.getDate() + 1);
    //         startNow = added;
    //         added = new Date(startNow);
    //         added.setDate(startNow.getDate() + 6);
    //         endNow = added;
    //         if (endNow >= maxDate || i == 52) {
    //           endNow = maxDate;
    //         }
    //       }
    //       this.data.weekList.start[i] = startNow;
    //       this.data.weekList.end[i] = endNow;
    //     }
    // }
  }
};
</script>
