<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b>{{title}} {{year}}</b>
    </span>
    <vs-table :data="data">
      <template slot="thead">
        <vs-th>Week</vs-th>
        <vs-th>Start</vs-th>
        <vs-th>End</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].email">{{ data[indextr].week }}</vs-td>
          <vs-td :data="data[indextr].username">{{ data[indextr].start_date }}</vs-td>
          <vs-td :data="data[indextr].id">{{ data[indextr].end_date }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>
<script>
export default {
  props: {
    year: {
      type: Number
    },
    baseUrl: {
      type: String
    },

    action: {
      type: String
    }
  },
  data() {
    return {
      title: "Weekly Date",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      data: []
    };
  },
  methods: {
    getData() {
      this.$vs.loading();
      this.$http.get(this.baseUrl + "/" + this.year).then(resp => {
        if (resp.status == "success") {
          this.data = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
          console.log(resp.data);
        }
      });
    }
  },
  mounted() {
    this.getData();
  },
  computed: {},
  watch: {
    year() {
      this.getData();
    }
  }
};
</script>